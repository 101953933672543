/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}
/* Remove list styles on ul, ol elements */
ul,
ol,
li {
  list-style: none;
}
/* A elements that don't have a class get default styles */
a {
  text-decoration-skip-ink: auto;
  color: #484848;
}
/* Make images easier to work with */
img {
  vertical-align: top;
}
/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}
